import {useFormik} from 'formik'
import * as Yup from 'yup'
import {IPersonalizationForm} from '../core/_model'
import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'

export const usePersonalizationFormState = () => {
  const navigate = useNavigate()

  // define the validation schema using Yup
  const ComparePerformanceSchema = Yup.object().shape({
    investment_goal: Yup.string(),
    risk_tolerance: Yup.string(),
    investment_experience: Yup.string(),
    time_horizon: Yup.string(),
    liquidity_needs: Yup.string(),
    income_needs: Yup.string(),
    tech_comfort: Yup.string(),
    initial_investment_amount: Yup.string(),
    contributions: Yup.string(),
    contributions_frequency: Yup.string(),
    initial_deposit_capability: Yup.string(),
    asset_preference: Yup.string(),
  })

  // define the initial form values
  const initialValues: IPersonalizationForm = {
    investment_goal: '',
    risk_tolerance: '',
    investment_experience: '',
    time_horizon: '',
    liquidity_needs: '',
    income_needs: '',
    tech_comfort: '',
    initial_investment_amount: '',
    contributions: '',
    contributions_frequency: '',
    initial_deposit_capability: '',
    asset_preference: '',
  }

  // formik handle submit
  const handleSubmit = async (values: any) => {
    // convert form values to a JSON string
    const personalizationFormOBJ = JSON.stringify(values)

    // store the JSON string in localStorage under the key 'personalizationFormObj'
    localStorage.setItem('personalizationFormObj', personalizationFormOBJ)

    // retrieve the item from localStorage to confirm it was stored
    const storedItem = localStorage.getItem('personalizationFormObj')

    // check if the stored item exists before navigating
    if (storedItem) {
      navigate('/chat-bot') // Navigate to the '/chat-bot' route
    } else {
      // optionally handle the case where the data wasn't successfully saved
      console.error('Failed to save form data to localStorage.')
    }
  }

  // formik
  const formik = useFormik<IPersonalizationForm>({
    initialValues,
    enableReinitialize: true,
    validationSchema: ComparePerformanceSchema,
    onSubmit: handleSubmit,
  })

  // handle change RoR/compounding growth
  const handleCompoundingFreqChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const {value} = event.target
    formik.setFieldValue('contributions_frequency', value)
  }

  // scroll to personalization form on chevron-down click
  const scrollToForm = () => {
    // calculate 100vh position
    const height = window.innerHeight + 100

    // Scroll to 100vh position
    window.scrollTo({
      top: height,
      behavior: 'smooth',
    })
  }

  // scroll to personalization form on scroll
  const handleScroll = (e: WheelEvent) => {
    if (e.deltaY > 0) {
      // Scrolling down
      scrollToForm()
    }
    // else {
    //   // Scrolling up
    //   window.scrollTo({
    //     top: 0,
    //     behavior: 'smooth',
    //   })
    // }
  }

  useEffect(() => {
    window.addEventListener('wheel', handleScroll, {passive: false})

    return () => {
      window.removeEventListener('wheel', handleScroll)
    }
  }, [])

  return {
    formik,
    scrollToForm,
    handleCompoundingFreqChange,
  }
}
