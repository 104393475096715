import StackBarChart from '../../charts/StackBarChart'
import CustomOverlayTooltip from '../../components/CustomOverlayTooltip'
import ShareAbleBubble from './ShareableBubble'
import {downloadMessage} from '../chatbotHelper'
import ReactMarkdown from 'react-markdown'
import FeedbackSection from '../../components/FeedbackSection'

interface Props {
  id: string
  message: string
  userRole: string
  chartData: any
  imageSrc: string
  messageType?: string
}

const Threads: React.FC<Props> = (props) => {
  // destructuring props
  const {message, userRole, chartData, imageSrc, messageType, id} = props

  return (
    <>
      {userRole === 'user' ? (
        <div className='message-container d-flex mb-7 justify-content-end'>
          <ReactMarkdown className={`${userRole}-chat mb-0 px-4 pt-3`}>{message}</ReactMarkdown>
          <img alt='Logo' src={imageSrc} className='avatars' />
        </div>
      ) : !messageType ? (
        <>
          <div className='d-flex p-md-2 mb-7'>
            <img alt='Logo' src={imageSrc} className='avatars' />

            <div
              id={id}
              className={
                chartData
                  ? 'bot-container message-container w-100'
                  : 'bot-container message-container'
              }
            >
              <div className={`${userRole}-chat mb-0 p-3`}>
                <ReactMarkdown>{message}</ReactMarkdown>
              </div>

              {chartData && chartData.length !== 0 && (
                <>
                  <StackBarChart data={chartData} />
                  <div className='d-flex justify-content-end align-items-center my-5'>
                    <CustomOverlayTooltip
                      content='Please note that the information may not be real-time and could have changed since the last update.'
                      className='tooltip-logo'
                    />
                    {chartData[0]?.citation?.link ? (
                      <a
                        className='ms-2 me-4'
                        target='_blank'
                        href={chartData[0]?.citation?.link}
                        rel='noreferrer'
                      >
                        Data Source
                      </a>
                    ) : (
                      <p className='paragraph-small ms-2 me-4 mb-0'>Data Disclaimer</p>
                    )}
                  </div>
                  <div className='d-flex justify-content-end align-items-center my-5'>
                    {/* download and share button */}
                    {id !== 'message0' && id !== 'message1' && (
                      <div className='d-flex justify-content-end align-items-center my-3'>
                        <section>
                          <FeedbackSection sectionName={message} />
                        </section>
                        <button
                          className='chat-button chat-button-gradient ms-5'
                          title='Download'
                          onClick={() => downloadMessage(id, 'Message.png')}
                        >
                          <i className='fa fa-arrow-down' style={{color: '#fff'}} />
                        </button>
                        <ShareAbleBubble messageId={id} />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className='d-flex p-4 mb-7'>
          <img alt='Logo' src={imageSrc} className='avatars' />
          <p className={`localy-generated-chat bot-container mb-0 p-4 ml-2`}>
            <ReactMarkdown>{message}</ReactMarkdown>
          </p>
        </div>
      )}
    </>
  )
}

export default Threads
