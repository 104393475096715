import React from 'react'
import {TooltipProps} from 'recharts'
import {toUSD} from './charts.helper'

const TimeSeriesTooltip: React.FC<TooltipProps<number, string>> = ({active, payload}) => {
  if (!active || !payload || payload.length === 0 || payload[0]?.payload?.label === 'Custom') {
    return null
  }

  // chart data
  const data = payload[0].payload

  const LossLabel = ({value}: {value: number}) => {
    return (
      <p className='paragraph-x-small mb-0 label-loss'>
        Loss: <span className='label-bold'>{toUSD(Math.abs(value))}</span>
      </p>
    )
  }

  const GainLabel = ({value}: {value: number}) => {
    return (
      <p className='paragraph-x-small mb-0 label-gain'>
        Gain: <span className='label-bold'>{toUSD(value)}</span>
      </p>
    )
  }

  const ContributionsLabel = ({value}: {value: number}) => {
    return (
      <p className='paragraph-x-small mb-0 label-contributions'>
        Contributions: <span className='label-bold'>{toUSD(value)}</span>
      </p>
    )
  }

  return (
    <div className='custom-tooltip'>
      <p className='paragraph-small label-main'>{data.investment_name}</p>

      {!data.is_loss ? <GainLabel value={data?.gains} /> : <LossLabel value={data?.gains} />}
      <p className='paragraph-x-small mb-0 label-initial-deposit'>
        {<ContributionsLabel value={data?.contributions} />}
      </p>
      <p className='paragraph-x-small mb-0 label-initial-deposit'>
        Starting amount: <span className='label-bold'>{toUSD(data?.starting_amount)}</span>
      </p>
    </div>
  )
}

export default TimeSeriesTooltip
