import {FEEDBACK_SECTION} from '../../../constants/AppConstants'
import FeedbackButton from './FeedbackButton'
import FeedbackInputField from './FeedbackInputField'
import { useFeedbackDataState } from './FeedbackUtils'
interface IFeedbackSectionProps {
  sectionName: string
}

const FeedbackSection: React.FC<IFeedbackSectionProps> = ({sectionName}) => {
  // extracting useFeedbackDataState
  const {
    // states
    likeSelected,
    dislikeSelected,
    likeHovered,
    dislikeHovered,
    showCommentsText,
    showThanksText,
    showFeedbackField,
    // functions
    handleLikeClick,
    handleDislikeClick,
    handleLikeMouseOver,
    handleLikeMouseOut,
    handleDislikeMouseOver,
    handleDislikeMouseOut,
    handleInputChange,
    handleShowFeedbackField,
    handleShowThanksText,
  } = useFeedbackDataState()

  return (
    <>
      <section className='d-flex justify-content-end align-items-center'>
        <FeedbackButton
          initialImage={FEEDBACK_SECTION.IMAGES.LIKE_OUTLINE}
          filledImage={FEEDBACK_SECTION.IMAGES.LIKE_FILLED}
          alt='thumbs-up'
          selected={likeSelected}
          hovered={likeHovered}
          onClick={() => handleLikeClick(sectionName)}
          onMouseOver={handleLikeMouseOver}
          onMouseOut={handleLikeMouseOut}
          customClassName={'me-1'}
        />
        <FeedbackButton
          initialImage={FEEDBACK_SECTION.IMAGES.DISLIKE_OUTLINE}
          filledImage={FEEDBACK_SECTION.IMAGES.DISLIKE_FILLED}
          alt='thumbs-down'
          hovered={dislikeHovered}
          selected={dislikeSelected}
          onClick={() => handleDislikeClick(sectionName)}
          onMouseOver={handleDislikeMouseOver}
          onMouseOut={handleDislikeMouseOut}
          customClassName={''}
        />
      </section>
      <div className='d-flex flex-column justify-content-center align-items-end'>
        {/* comments text */}
        {showCommentsText ? (
          <p className='pink-text mb-0 cursor-pointer' onClick={handleShowFeedbackField}>
            comments?
          </p>
        ) : null}
        {/* thanks text */}
        {showThanksText && !showCommentsText && !showFeedbackField ? (
          <p className='mb-0'>Thanks!</p>
        ) : null}
        {/* feedback input field */}
        {showFeedbackField ? (
          <FeedbackInputField
            onChange={handleInputChange}
            onSubmit={() => handleShowThanksText(sectionName)}
          />
        ) : null}
      </div>
    </>
  )
}

export default FeedbackSection
