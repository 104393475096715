import {BarChart, Bar, XAxis, ResponsiveContainer, Tooltip, LabelList, Text, Cell} from 'recharts'
import useScreenWidth from '../hooks/useScreenWidth'
import {stackBarColors, toAbbreviatedNumber} from './components/charts.helper'
import StackBarChartTooltip from './components/StackBarChartTooltip'

const StackBarChart: React.FC<any> = (props) => {
  // destructuring props
  const {data} = props
  // calculating screen width hook
  const screenWidth = useScreenWidth()

  // calculating chart height
  let chartPadding
  let _chartHeight
  // calculating chart padding
  chartPadding =
    screenWidth < 546
      ? {top: 50, bottom: 15, right: 30, left: 30}
      : {top: 80, bottom: 30, right: 40, left: 40}

  _chartHeight = screenWidth > 767 ? 410 : screenWidth > 567 ? 250 : 200
  const labelPadding = screenWidth < 776 ? 8 : 0
  const labelTextAngle = screenWidth < 776 ? -30 : 0
  const labelFVAngle = screenWidth < 410 ? -30 : 0

  // customized bar top label
  const CustomizedBarTopLabel = (props: any) => {
    const {x, y, width, value} = props

    const futureValue = '$' + toAbbreviatedNumber(parseFloat(value?.toFixed(2)))
    return (
      <Text
        x={x + width / 2}
        y={screenWidth > 767 ? y - 23 : y - 15}
        textAnchor='middle'
        className='custom-label custom-label-bold'
        fill={stackBarColors.startingAmount}
        angle={labelFVAngle}
      >
        {futureValue ? futureValue : '$X'}
      </Text>
    )
  }

  //custom bottom labels of the chart
  const CustomizedAxisTick = (props: any) => {
    const {x, y, index, payload} = props

    return (
      <>
        <Text
          x={x}
          y={y + labelPadding}
          width={90}
          textAnchor='middle'
          verticalAnchor='start'
          angle={labelTextAngle}
          className='custom-tick-label'
          fill={stackBarColors.black}
        >
          {data[index]?.investment_name}
        </Text>
        <Text
          x={x}
          y={y + 30}
          width={90}
          textAnchor='middle'
          verticalAnchor='start'
          angle={labelTextAngle}
          className='custom-label custom-label-bold'
          fill={stackBarColors.startingAmount}
        >
          {payload?.value + '%'}
        </Text>
        {data[index].oldValue ? (
          <Text
            x={x}
            y={y + 40}
            width={90}
            textAnchor='middle'
            verticalAnchor='start'
            angle={labelTextAngle}
            className='custom-label custom-label-bold'
            fill={stackBarColors.startingAmount}
          >
            (Old Value)
          </Text>
        ) : null}
      </>
    )
  }

  return (
    <ResponsiveContainer width='90%' height={_chartHeight} id='chart-container' className='m-auto'>
      <BarChart
        data={data}
        margin={chartPadding}
        style={{backgroundColor: '#DDF2F4'}}
        barSize={screenWidth < 546 ? 50 : 100}
        barCategoryGap={10} // adjust the gap between bars in a category (group)
        barGap={5} // adjust the gap between different categories (groups)
      >
        <XAxis
          dataKey='rate_of_return_value'
          axisLine={false}
          tickLine={false}
          interval={0}
          width={100}
          minTickGap={5}
          tick={<CustomizedAxisTick />}
        />

        {/* tooltip */}
        <Tooltip content={<StackBarChartTooltip />} />
        {/* bars */}
        <Bar dataKey='starting_amount' stackId='assets' fill={stackBarColors.startingAmount} />
        <Bar dataKey='contributions' stackId='assets' fill={stackBarColors.contributions} />
        <Bar dataKey='gains' stackId='assets' fill={stackBarColors.gain}>
          {data.map((entry: any, index: number) => (
            <Cell
              key={`cell-${index}`}
              fill={`${entry?.is_loss ? stackBarColors.loss : stackBarColors.gain}`}
            />
          ))}
          <LabelList dataKey='future_value' content={<CustomizedBarTopLabel />} position='top' />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}
export default StackBarChart
