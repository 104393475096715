import axios from 'axios'
import ReactGA from 'react-ga4'
import {toJpeg} from 'html-to-image'
import {toast} from 'react-toastify'

// for dev
let measurementIdForGA4Analytics = 'G-C53H5RVHEJ'
var currentUrl = window.location.href

// Check if the URL starts with "https://chloee.xyz/"
if (currentUrl.startsWith('https://chloee.xyz/')) {
  measurementIdForGA4Analytics = 'G-C53H5RVHEJ'
}
// Check if the URL starts with "https://dev.chloee.xyz/"
else if (currentUrl.startsWith('https://dev.chloee.xyz/')) {
  measurementIdForGA4Analytics = 'G-9PWZMBKNVM'
}
// If the URL doesn't match either site
else {
  console.log('running on local machine')
}
// process.env.REACT_APP_API_URL === 'https://chloee.xyz/api' ? 'G-C53H5RVHEJ' : 'G-9PWZMBKNVM'
ReactGA.initialize(measurementIdForGA4Analytics)
// streamId = 6704117336 ,  6704136434

//send analytics
export const sendAnalytics = async (pageTitle: string) => {
  ReactGA.send({hitType: 'pageview', page: window.location.pathname, title: pageTitle})
}

//send data to spread sheet
export const sendToSheets = async (
  user: string,
  bot: string,
  receiverEmail: string,
  date: any,
  domain: string,
  ref?: string
) => {
  if (!ref) {
    ref = ''
  }
  const encodedUserMessage = encodeURIComponent(user)
  const encodedBotMessage = encodeURIComponent(bot)
  const receiver_email = encodeURIComponent(receiverEmail)
  const current_date = encodeURIComponent(date)
  const current_domain = encodeURIComponent(domain)
  const referal = encodeURIComponent(ref)
  const scriptUrl = `${process.env.REACT_APP_GOOGLE_SHEETS_DEPLOYMENT_URL}?current_date=${current_date}&domain=${current_domain}&receiver_email=${receiver_email}&user_msg=${encodedUserMessage}&bot_msg=${encodedBotMessage}&referal=${referal}&host=my_prod`
  try {
    const response = await fetch(scriptUrl)
    if (response.status === 200) {
      //   const result = response.data
      //   console.log('Message sent successfully. Row:', result)
      return
    } else {
      console.error('Error sending message:', response.statusText)
    }
  } catch (error) {
    console.error('Error sending message:', error)
  }
}
// Function to get the value of a specific query parameter from the current page URL
export const getQueryParamFromCurrentUrl = (param: string) => {
  const parsedUrl = new URL(window.location.href)
  return parsedUrl.searchParams.get(param)
}

// parse the raw html into the simple string
export const htmlToText = (htmlString: string) => {
  // Create a new DOMParser
  var parser = new DOMParser()

  // Parse the HTML string
  var doc = parser.parseFromString(htmlString, 'text/html')

  // Extract the text content
  var textContent = doc.body.textContent || ''

  return textContent.trim()
}

export const getRandomNumber = (lowerLimit: number, upperLimit: number) => {
  // Generate a random number within the specified range (inclusive of both limits)
  const randomNumber = Math.floor(Math.random() * (upperLimit - lowerLimit + 1)) + lowerLimit
  return randomNumber
}

export const downloadMessage = (messageId: string, imageName: string) => {
  // setting toastify success/error
  const notifyError = (msg: string) => toast.error(msg)

  const chartContainer = document.getElementById(messageId)

  if (chartContainer) {
    toJpeg(chartContainer, {cacheBust: false})
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.download = imageName
        link.href = dataUrl
        link.click()
      })
      .catch((err) => {
        notifyError('An error occurred.')
      })
  }
}

export const extractSubdomainFromCurrentUrl = () => {
  // Get the hostname from the current URL
  const domain = window.location.hostname

  // Split the hostname by the dots to separate the parts
  const parts = domain.split('.')

  // Remove the top-level domain (TLD) part
  if (parts.length > 2) {
    parts.pop() // This removes the last part (TLD)
  }

  // The remaining parts are the subdomain(s), joined with a dot
  const subdomain = parts.join('.')

  // Return the subdomain, which will be 'dev.chloee' for your example URL
  return subdomain
}
