import { v4 as uuidv4 } from 'uuid';

const STORAGE_KEY = 'device_info';
const EXPIRATION_TIME = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

const getDeviceInfo = () => {
  const now = new Date().getTime();
  const storedData = localStorage.getItem(STORAGE_KEY);
  
  if (storedData) {
    const deviceInfo = JSON.parse(storedData);

    // Check if the stored device ID is still valid
    if (now < deviceInfo?.expiration) {
      return { ...deviceInfo, isExpired: false };
    }
    else {
      return { deviceId: null, expiration: null,  isExpired: true }; 
    }
  }

  // Generate a new device ID and set a new expiration time
  const newDeviceId = uuidv4();
  const newExpiration = now + EXPIRATION_TIME;
  const deviceInfo = {
    deviceId: newDeviceId,
    expiration: newExpiration,
    isRegistered: false
  };

  // Store the new device ID and expiration time in local storage
  localStorage.setItem(STORAGE_KEY, JSON.stringify(deviceInfo));
  return deviceInfo;
};

export default getDeviceInfo;
