import {useEffect, useRef, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {toast} from 'react-toastify'
import {addToWaitlist} from '../../../../modals/core/_request'
import getDeviceInfo from '../../../../helpers/GenerateDeviceInfo'
import {useJoinWaitlistContext} from '../../../context/JoinWaitlistContext'
import ReactGA from 'react-ga4'
import {useAuth} from '../../../../modules/auth'
import {UserRoles} from '../../../../config/UserRoles'
import {getUserByToken, login} from '../../../../modules/auth/core/_requests'
import clsx from 'clsx'

interface TemporaryUserRegisterModalProps {
  show: boolean
  onHide: () => void
}

// form schema
// form schema
const schema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})
const initialValues = {
  email: '',
}

// login formik
const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const loginInitialValues = {
  email: '',
  password: '',
}

const TemporaryUserRegisterModal: React.FC<TemporaryUserRegisterModalProps> = (props) => {
  let measurementIdForGA4Analytics = 'G-C53H5RVHEJ'
  var currentUrl = window.location.href
  if (currentUrl.startsWith('https://chloee.xyz/')) {
    measurementIdForGA4Analytics = 'G-C53H5RVHEJ'
  } else if (currentUrl.startsWith('https://dev.chloee.xyz/')) {
    measurementIdForGA4Analytics = 'G-9PWZMBKNVM'
  } else {
    console.log('running on local machine')
  }
  ReactGA.initialize(measurementIdForGA4Analytics)
  // setting toastify success/error
  const notifyError = (msg: string) => toast.error(msg)
  const notifySuccess = (msg: string) => toast.success(msg)

  // login formik
  const {saveAuth, setCurrentUser, currentUser} = useAuth()
  const {deviceInfo, setDeviceInfo} = useJoinWaitlistContext()

  const [isLoading, setIsLoading] = useState(false)
  const [isAlreadyRegisteredUser, setIsAlreadyRegisteredUser] = useState(false)

  // temporary register formik
  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      setIsLoading(true)
      //   let deviceInfo = getDeviceInfo()
      try {
        const res = await addToWaitlist(values?.email, deviceInfo?.deviceId)
        setIsLoading(false)
        notifySuccess('Thank you for joining us!')
        ReactGA.send({
          hitType: 'event',
          eventCategory: 'User',
          eventAction: 'Click',
          eventLabel: 'Save Button',
          eventValue: 200,
        })
        if (deviceInfo) {
          // Update the isRegistered property to true
          deviceInfo.isRegistered = true
          // Convert the object back to a string
          const updatedDeviceInfo = JSON.stringify(deviceInfo)
          // Store the updated string back in localStorage
          localStorage.setItem('device_info', updatedDeviceInfo)
          setDeviceInfo(JSON.parse(updatedDeviceInfo))
        }

        props?.onHide()
        formik.resetForm({})
      } catch (error: any) {
        if (error?.response?.data) {
          if (error?.response?.data?.message === 'User already exists in waitlist') {
            notifySuccess('You’re already on the waitlist. Please log in instead.')
            loginFormik.setFieldValue('email', values?.email)
            setIsAlreadyRegisteredUser(true)
          }
        } else {
          notifyError('Error occurred during the request.')
        }
        setIsLoading(false)
      }
    },
  })

  const loginFormik = useFormik({
    initialValues: loginInitialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setIsLoading(true)
      try {
        const {data: auth} = await login(values.email, values.password)
        saveAuth(auth)
        const {data: user} = await getUserByToken(auth.auth_token)
        setCurrentUser(user)
        if (user.user_role === UserRoles.user && !currentUser?.is_first_login) {
          // // set the user status to 'registered' in local storage 'device_info' key
          // if (deviceInfo) {
          //   // Update the isRegistered property to true
          //   deviceInfo.isRegistered = true
          //   // Convert the object back to a string
          //   const updatedDeviceInfo = JSON.stringify(deviceInfo)
          //   // Store the updated string back in localStorage
          //   localStorage.setItem('device_info', updatedDeviceInfo)
          //   setDeviceInfo(JSON.parse(updatedDeviceInfo))
          // }
        }
      } catch (error: any) {
        if (error.response?.data?.status === false) {
          // navigate('/auth/email-confirmation', {state: {email: values.email}})
        } else {
          setStatus('The login details are incorrect')
        }
        console.error(error)
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setIsLoading(false)
        if (error?.response?.status === 403) notifyError('User not verified or blocked')
      }
    },
  })
  const modalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setTimeout(() => {
      const backdrop = document.querySelector('.modal-backdrop.show')
      if (backdrop) {
        if (props.show) {
          backdrop.classList.add('gradient-backdrop')
        } else {
          backdrop.classList.remove('gradient-backdrop')
        }
      } else {
        console.log('Backdrop not found')
      }
    }, 1) // You may need to adjust the timing here
  }, [props.show])

  return (
    <>
      <Modal
        {...props}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        id='temporaryUserRegisterModal'
        ref={modalRef}
        onExited={() => {
          const backdrop = document.querySelector('.modal-backdrop.show') as HTMLElement
          if (backdrop) {
            backdrop.style.top = '0'
          }
        }}
      >
        {!isAlreadyRegisteredUser ? (
          <form onSubmit={formik.handleSubmit} noValidate className='form' id='customBar-form'>
            <Modal.Header closeButton>
              <Modal.Title
                id='contained-modal-title-vcenter'
                className='heading sub-heading-x-small'
              >
                Register your email and start chatting:
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='row m-0'>
                <div className='col-12 fv-row ps-0'>
                  <div
                    className={`input-container d-flex justify-content-center align-items-center p-0 ${
                      formik.touched.email && formik.errors.email ? 'error-border' : ''
                    }`}
                  >
                    <p className='m-0 currency-symbol d-none'>%</p>
                    <input
                      id='email'
                      type='email'
                      className='form-control form-control-lg form-control-solid bg-transparent border-0'
                      placeholder=' Enter your email'
                      {...formik.getFieldProps('email')}
                    />
                  </div>
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'> * {formik.errors.email}</div>
                    </div>
                  )}
                </div>
              </div>
              <Modal.Footer className='py-0'>
                <button
                  type='submit'
                  id='kt_sign_in_submit'
                  className='primary-btn border-0 mt-3'
                  style={{cursor: 'pointer'}}
                  disabled={isLoading}
                >
                  {!isLoading && <span className='indicator-label'>Register</span>}
                  {isLoading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </Modal.Footer>
            </Modal.Body>
          </form>
        ) : (
          <form
            className='form w-100'
            onSubmit={loginFormik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
          >
            <Modal.Header closeButton>
              <Modal.Title
                id='contained-modal-title-vcenter'
                className='heading sub-heading-x-small'
              >
                Sign In
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {loginFormik.status ? (
              <div className='mb-lg-5 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{loginFormik.status}</div>
              </div>
            ) : null}
            
              <div className='fv-row mb-8'>
                <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
                <input
                  placeholder='Email'
                  {...loginFormik.getFieldProps('email')}
                  className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': loginFormik.touched.email && loginFormik.errors.email},
                    {
                      'is-valid': loginFormik.touched.email && !loginFormik.errors.email,
                    }
                  )}
                  type='email'
                  name='email'
                  autoComplete='off'
                />
                {loginFormik.touched.email && loginFormik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{loginFormik.errors.email}</span>
                  </div>
                )}
              </div>

              <div className='fv-row mb-3'>
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
                <input
                  placeholder='Password'
                  type='password'
                  autoComplete='off'
                  {...loginFormik.getFieldProps('password')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': loginFormik.touched.password && loginFormik.errors.password,
                    },
                    {
                      'is-valid': loginFormik.touched.password && !loginFormik.errors.password,
                    }
                  )}
                />
                {loginFormik.touched.password && loginFormik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{loginFormik.errors.password}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='d-grid mb-10'>
                <button
                  type='submit'
                  id='kt_sign_in_submit'
                  className='btn btn-primary'
                  disabled={loginFormik.isSubmitting || !loginFormik.isValid}
                >
                  {!isLoading && <span className='indicator-label'>Login</span>}
                  {isLoading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </Modal.Body>
          </form>
        )}
      </Modal>
    </>
  )
}
export default TemporaryUserRegisterModal
