/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect, useState} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {AdminRoutes} from './AdminRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import {UserRoles} from '../config/UserRoles'
import {UserRoutes} from './UserRoutes'
import CanvaPreview from '../user/pages/home/htmlPage/CanvaPreview'
import {ResetPassword} from '../modules/auth/components/ResetPassword'
import {ChangePassword} from '../user/pages/auth/ChangePassword'
import WaitListPage from '../user/pages/home/htmlPage/WaitListPage'
import Chatbot from '../user/modules/chatbot/Chatbot'
import TemporaryUserRegisterModal from '../user/modules/chatbot/components/TemporaryUserRegisterModal'
import { useJoinWaitlistContext } from '../user/context/JoinWaitlistContext'
import { UserLayout } from '../../_metronic/layout/UserLayout'


/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()

  // fetching personalized-form from local storage
  const personalizationFormObj = localStorage.getItem('personalizationFormObj')
  const waitlistStep = Number(localStorage.getItem('waitlist-step'))

  const RootRedirect = () => {
    useEffect(() => {
      window.location.href = 'https://joinwaitlist.chloee.xyz'
    }, [])

    return null
  }
  const { deviceInfo, showTempRegisterModal, setShowTempRegisterModal } = useJoinWaitlistContext()

  const pathsToCheck = ['chat-bot']
  const isChatbotInURL = pathsToCheck.some((path) => window.location.pathname.includes(path))

  const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
  const isUserLoggedIn = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)

  useEffect(()=>{
    const timeoutId = setTimeout(() => {
      setShowTempRegisterModal(true);
    }, 2000);

    return () => clearTimeout(timeoutId)
  },[])


  return (
    <>
      <BrowserRouter basename={PUBLIC_URL}>
        <Routes>
          <Route element={<App />}>
            <Route path='error/*' element={<ErrorsPage />} />
            <Route path='logout' element={<Logout />} />
            {currentUser ? (
              <>
                {/* if loggedIn user is admin then navigate to admin routes */}
                {currentUser.user_role === UserRoles.admin ? (
                  <>
                    <Route path='/*' element={<AdminRoutes />} />
                    <Route index element={<Navigate to='/dashboard' />} />
                  </>
                ) : null}
                {/* if loggedIn user is admin then navigate to admin routes */}
                {currentUser.user_role === UserRoles.user ? (
                  <>
                    {currentUser.is_first_login ? (
                      <>
                        <Route path='/change-password' element={<ChangePassword />} />
                        <Route path='*' element={<Navigate to='/change-password' />} />
                      </>
                    ) : (
                      <>
                        <Route
                          index
                          element={
                            <Navigate to={!personalizationFormObj ? '/home' : '/chat-bot'} />
                          }
                        />
                        <Route path='/*' element={<UserRoutes />} />
                      </>
                    )}
                  </>
                ) : null}
              </>
            ) : (
              <>
                <Route element={<UserLayout />}>
                  <Route path='/chat-bot' element={<Chatbot />} />
                </Route>
                <Route index element={<Navigate to='/chat-bot' />} />
                <Route path='/join-waitlist' element={<WaitListPage />} />
                <Route path='/preview/:slug' element={<CanvaPreview />} />

                <Route path='auth/*' element={<AuthPage />} />
                <Route
                  path='/'
                  element={
                    !waitlistStep ? (
                      <RootRedirect />
                    ) : (
                      <Navigate to={waitlistStep === 3 ? '/auth' : '/join-waitlist'} />
                    )
                  }
                />
                <Route
                  path='*'
                  element={<Navigate to={waitlistStep === 3 ? '/auth' : '/join-waitlist'} />}
                />
              </>
            )}
          </Route>
        </Routes>
      </BrowserRouter>

      {!deviceInfo?.isRegistered && isChatbotInURL && !isUserLoggedIn && (
        <TemporaryUserRegisterModal show={showTempRegisterModal} onHide={() => setShowTempRegisterModal(false)} />
      )}
    </>
  )
}

export {AppRoutes}
