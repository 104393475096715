import {useEffect, useRef, useState} from 'react'
import {toast} from 'react-toastify'
import useFocus from '../hooks/useFocus'
import Chats from './components/Chats'
import {IMessagesInfo} from './core/_model'
import {addRetainedUser, filterUserInput, submitChatRequest} from './core/_request'
import * as DOMPurify from 'dompurify'
import {
  sendToSheets,
  sendAnalytics,
  htmlToText,
  getRandomNumber,
  extractSubdomainFromCurrentUrl,
  getQueryParamFromCurrentUrl,
} from './chatbotHelper'
import {
  investmentTipsAndSuggestions,
  searchInProgressStrings,
  informationGatheringStrings,
} from './TipsAndSuggestions'
import {ERROR} from '../../../constants/AppConstants'
import {riskToleranceAssets} from './core/_chatbotConfig'
import {useAuth} from '../../../modules/auth'
import getDeviceInfo from '../../../helpers/GenerateDeviceInfo'
import {useJoinWaitlistContext} from '../../context/JoinWaitlistContext'

interface ResponseBotObject {
  message: string
  options?: string[]
  sender: string
  type?: string
}

const Chatbot: React.FC = () => {

  // Function to get the current timestamp
  function getCurrentTimestamp(): number {
    return new Date().getTime()
  }
  const device = JSON.parse(localStorage.getItem('device_info') || '')
  // Function to get the stored timestamp from localStorage
  function getStoredTimestamp(key: string): number | null {
    const storedValue = localStorage.getItem(key)
    return storedValue ? parseInt(storedValue, 10) : null
  }

  // Function to set the timestamp in localStorage
  function setStoredTimestamp(key: string, timestamp: number): void {
    localStorage.setItem(key, timestamp.toString())
  }

  // Function to remove a timestamp from localStorage
  function removeStoredTimestamp(key: string): void {
    localStorage.removeItem(key)
  }

  // Check if two timestamps are on the same calendar day
  function isSameDay(ts1: number, ts2: number): boolean {
    const date1 = new Date(ts1)
    const date2 = new Date(ts2)
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    )
  }

  // Function to track user actions
  async function trackUserActions() {
    const initialVisitKey = `initialVisit`
    const lastTrackedDayKey = `lastTrackedDay`
    const lastTracked7DayKey = `lastTracked7Day`

    const currentTime = getCurrentTimestamp()
    const initialVisitTime = getStoredTimestamp(initialVisitKey)

    if (!initialVisitTime) {
      setStoredTimestamp(initialVisitKey, currentTime)
      console.log('First time visit. No tracking needed.')
      return
    }

    if (isSameDay(currentTime, initialVisitTime)) {
      console.log('Action within the same day. No tracking needed.')
      //  return;
    }

     const dayDifference = Math.floor((currentTime - initialVisitTime) / (24 * 60 * 60 * 1000));
    // let dayDifference = 1
    if (dayDifference >= 1 && dayDifference < 7) {
      addRetainedUser({
        retainedDuration: 'Returned after 1 day',
        deviceId: device?.deviceId,
        userId: currentUser ? currentUser.id : null,
        actionDate: initialVisitTime,
      })
    } else if (dayDifference >= 7) {
      addRetainedUser({
        retainedDuration: 'Returned after 7 day',
        deviceId: device?.deviceId,
        userId: currentUser ? currentUser.id : null,
        actionDate: initialVisitTime,
      })
    } else {
      console.log('No special tracking needed.')
    }
  }
  
  const {currentUser} = useAuth()
  const {deviceInfo, setShowTempRegisterModal} = useJoinWaitlistContext()

  const [userResponse, setUserResponse] = useState<string>('')
  const [botResponse, setBotResponse] = useState<ResponseBotObject>({
    message: '',
    sender: 'bot',
  })
  const [previousBarData, setPreviousBarData] = useState<any>({})
  const [threadId, setThreadId] = useState<string | null>(null)
  const [messages, setMessages] = useState<IMessagesInfo[]>([])
  const [chartData, setChartData] = useState<any[]>([])
  const [showChat, setShowChat] = useState<boolean>(false)
  const {inputRef, focusInput} = useFocus()
  const [isLoading, setLoading] = useState<boolean>(false)
  const [showSuggestedBubble, setShowSuggestedBubble] = useState<boolean>(true)
  const [suggestedMessages, setSuggestedMessages] = useState([
    {
      id: 1,
      message: 'How can I start investing with a small budget?',
    },
    {
      id: 2,
      message: 'Can you show me the growth of saving $50 per month versus $100 in an investment?',
    },
    {
      id: 3,
      message: `I've saved up $1,000. What's the best way to invest this amount?`,
    },
    {
      id: 4,
      message: 'If I start investing now, what might my portfolio look like after 5 years?',
    },
  ])
  // const [deviceInfo, setDeviceInfo] = useState<any>()
  const notifyError = (msg: string) => toast.error(msg)
  useEffect(() => {
    sendAnalytics('Chat Bot')
    trackUserActions()
  }, [])
  useEffect(() => {
    if (showChat) {
      focusInput()
    }
  }, [showChat, focusInput])

  // let deviceInfo: any
  useEffect(() => {
    // setDeviceInfo(getDeviceInfo())
    // console.log('789878', deviceInfo?.isRegistered)
    // deviceInfo = getDeviceInfo()
  }, [deviceInfo])

  // fetching personalized-form from local storage
  const personalizationFormObj = localStorage.getItem('personalizationFormObj')
  const parsedPersonalizationFormObj = personalizationFormObj
    ? JSON.parse(personalizationFormObj)
    : {}

  let domainName = extractSubdomainFromCurrentUrl()

  // // calculate gains
  // const calculateGains = (starting_amount: number, contributions: number, future_value: number) => {
  //   return future_value - contributions - starting_amount
  // }

  // // calculate future value
  // const calculateFutureValue = (
  //   starting_amount: number,
  //   contributions: number,
  //   contributions_frequency: number,
  //   rate_of_return: number,
  //   years_to_grow: number,
  //   investment_name: string
  // ) => {
  //   const n = contributions_frequency !== 0 ? contributions_frequency : 1
  //   const t = years_to_grow
  //   const i = rate_of_return / 100 / n
  //   const compoundFactor = Math.pow(1 + i, n * t)
  //   const principalComponent = starting_amount * compoundFactor
  //   const contributionComponent = contributions * ((compoundFactor - 1) / i)
  //   const futureValue = principalComponent + contributionComponent
  //   const calculated_contributions = contributions * contributions_frequency * years_to_grow
  //   const gains = calculateGains(starting_amount, calculated_contributions, futureValue)

  //   return {
  //     starting_amount: starting_amount,
  //     contributions: calculated_contributions,
  //     contributions_frequency: contributions_frequency,
  //     rate_of_return_value: rate_of_return,
  //     years_to_grow: years_to_grow,
  //     investment_name: investment_name,
  //     future_value: futureValue,
  //     gains: gains,
  //     is_loss: gains < 0 ? true : false,
  //   }
  // }

  // // filtering default investment based on the user preference
  // const findAssets = (riskType: string): any => {
  //   let chartDataArray

  //   if (riskType === 'Low') {
  //     chartDataArray = riskToleranceAssets[0].map((asset: any) =>
  //       calculateFutureValue(
  //         parsedPersonalizationFormObj?.initial_investment_amount ?? 1000, // starting amount
  //         parsedPersonalizationFormObj?.contributions ?? 0, // contributions
  //         parsedPersonalizationFormObj?.contributions_frequency, // contributions frequency
  //         asset.ROR, // rate of return
  //         5, // years to grow
  //         asset.label //investment name
  //       )
  //     )
  //     return chartDataArray
  //   }
  //   if (riskType === 'Medium') {
  //     chartDataArray = riskToleranceAssets[1].map((asset: any) => {
  //       return calculateFutureValue(
  //         parsedPersonalizationFormObj?.initial_investment_amount ?? 1000, // starting amount
  //         parsedPersonalizationFormObj?.contributions ?? 0, // contributions
  //         parsedPersonalizationFormObj?.contributions_frequency, // contributions frequency
  //         asset.ROR, // rate of return
  //         5, // years to grow
  //         asset.label //investment name
  //       )
  //     })
  //     return chartDataArray
  //   }
  //   if (riskType === 'High') {
  //     chartDataArray = riskToleranceAssets[2].map((asset: any) =>
  //       calculateFutureValue(
  //         parsedPersonalizationFormObj?.initial_investment_amount ?? 1000, // starting amount
  //         parsedPersonalizationFormObj?.contributions ?? 0, // contributions
  //         parsedPersonalizationFormObj?.contributions_frequency, // contributions frequency
  //         asset.ROR, // rate of return
  //         5, // years to grow
  //         asset.label //investment name
  //       )
  //     )
  //     return chartDataArray
  //   }
  // }

  const startMessage = parsedPersonalizationFormObj?.risk_tolerance
    ? 'Say Hi to user at the start of each new thread and inform user that we have collected your personalization information and note please keep the answer very short. Also, ask user What questions can I help you with? or similar questions in friendly manner.'
    : 'Say Hi to user at the start of each new thread. Also, ask user What questions can I help you with? or similar questions in friendly manner'
  const referalValueFromCurrentUrl = getQueryParamFromCurrentUrl('ref')
  const submitChatRequestHandler = async () => {
    try {
      if (!currentUser && deviceInfo?.deviceId) {
        setMessages([
          {
            message:
              "Hey there! 👋 Welcome to our investment planning chat. What questions can I help you with today, or is there something specific you'd like to know about investing? 😊",
            sender: 'bot',
            chartData: null,
          },
        ])
      } else {
        setLoading(true)
        const res = await submitChatRequest(
          startMessage,
          threadId,
          personalizationFormObj,
          currentUser?.id,
          deviceInfo?.isRegistered == true && !currentUser ? deviceInfo?.deviceId : null
        )
        const {text, existingThreadId, limitReached} = res.data

        if (limitReached) {
          notifyError(limitReached)
        } else {
          const newMessage = {
            message: text,
            sender: 'bot',
            chartData: null,
          }
          setMessages([newMessage])
          !threadId && setThreadId(existingThreadId)
        }
      }
    } catch (error: any) {
      if (error?.response?.status === 429) return notifyError(ERROR.too_many_requests)
      else if (error?.response?.status === 403)
        return notifyError('Oops! You have reached your maximum tokens limit.')
      else notifyError(ERROR.message)
      // console.log(error?.response)
    } finally {
      setLoading(false)
      setShowChat(true)
    }
  }

  // stacking up messages
  useEffect(() => {
    if (messages.length === 0) {
      submitChatRequestHandler()
    } else {
      let temp2 = [...messages]
      const botResponseFormatted: IMessagesInfo = {
        message: botResponse.message,
        sender: 'bot',
        chartData: chartData,
        messageType: botResponse.type,
      }

      temp2.push(botResponseFormatted)
      if (!botResponse.type) {
        temp2 = temp2.filter((message) => {
          return message.messageType === undefined || message.sender === 'user'
        })
      }
      // to keep track of whole conversation
      setMessages(temp2)
      setUserResponse('')
    }
  }, [botResponse])

  // event handlers
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUserResponse(e.target.value)
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>, defaultMessage = '') => {
    e.preventDefault()
    setShowSuggestedBubble(false)
    const userRes = defaultMessage ? defaultMessage : userResponse
    if (userRes.length < 1) return

    let tempArray = [...messages]
    const botResponseFormatted: IMessagesInfo = {
      message: userRes,
      sender: 'user',
      chartData: null,
    }
    tempArray.push(botResponseFormatted)
    setMessages(tempArray)
    setLoading(true)
    let timerId: any

    try {
      const responseFromFilter = await filterUserInput(userRes)
      if (!responseFromFilter.data) {
        setBotResponse({
          message: "I'm sorry, I can only answer questions related to finance and investment",
          sender: 'bot',
        })
        setChartData([])
        return
      }
      let counting = 0
      let message = ''

      timerId = setInterval(() => {
        counting += 1

        switch (counting) {
          case 10:
            message += informationGatheringStrings[getRandomNumber(0, 54)]
            break
          case 20:
            message += searchInProgressStrings[getRandomNumber(0, 99)]
            break
          case 30:
            message += investmentTipsAndSuggestions[getRandomNumber(0, 177)]
            break
          case 50:
            message += investmentTipsAndSuggestions[getRandomNumber(0, 177)]
            break
        }

        if (message) {
          setBotResponse({
            message: message,
            sender: 'bot',
            type: 'generatedFromLocal',
          })
        }
        message = ''
        if (counting >= 30) {
          clearInterval(timerId)
        }
      }, 1000)

      // const startTime: any = new Date()
      const res = await submitChatRequest(
        userRes,
        threadId,
        personalizationFormObj,
        currentUser?.id,
        deviceInfo?.isRegistered == true && !currentUser ? deviceInfo?.deviceId : null
      )

      // const endTime: any = new Date()
      // const elapsedMilliseconds = endTime - startTime
      // const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000)

      const {text, existingThreadId, chart_data, limitReached} = res.data
      if (limitReached) {
        notifyError(limitReached)
      } else {
        if (
          chart_data.length === 1 &&
          chart_data[0].investment_name === previousBarData?.investment_name
        ) {
          let tempArray = [...chart_data] // Create a new array to avoid mutating the original
          tempArray.push(previousBarData)
          setChartData(tempArray)
        } else {
          setChartData(chart_data && chart_data)
        }

        !existingThreadId && setThreadId(existingThreadId)
        // set response text

        setBotResponse({
          message: res.data?.response ? res.data.response : text,
          sender: 'bot',
        })

        if (chart_data.length === 1) {
          setPreviousBarData({...chart_data[0], oldValue: true})
        }
        const date = new Date()

        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`

        const formattedTime = `${date.getHours().toString().padStart(2, '0')}:${date
          .getMinutes()
          .toString()
          .padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`

        const receiverEmail = extractEmailKeyFromUrl(window.location.href)
          ? extractEmailKeyFromUrl(window.location.href)
          : currentUser?.email

        // Get the value of the 'ref' parameter from the current page URL

        sendToSheets(
          userResponse,
          htmlToText(res.data?.response ? res.data.response : DOMPurify.sanitize(text)),
          receiverEmail ? receiverEmail : '',
          formattedDate + ' ' + formattedTime,
          domainName,
          referalValueFromCurrentUrl ? referalValueFromCurrentUrl : ''
        )
      }
    } catch (error: any) {
      if (error?.response?.status === 429) return notifyError(ERROR.too_many_requests)
      else if (error?.response?.status === 403)
        return notifyError('Oops! You have reached your maximum tokens limit.')
      else notifyError(ERROR.message)
    } finally {
      clearInterval(timerId)
      setLoading(false)
      setShowChat(true)
    }
  }

  const textAreaRef = useRef<HTMLTextAreaElement | null>(null)

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = '30px' // reset the height
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px` // set the height based on content
    }
  }, [userResponse])

  const handleKeyDown = (event: any) => {
    // check if Enter is pressed without the Shift key
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault() // prevent new line
      handleSubmit(event)
    }
  }
  function extractEmailKeyFromUrl(url: string): string | null {
    const urlParams = new URLSearchParams(url.split('?')[1])
    const emailValue = urlParams.get('email')
    return emailValue
  }

  // chat button enable/disable
  const isDisabled = isLoading || (!currentUser && deviceInfo?.isRegistered === false)

  const handleChatBarClick = () => {
    if (isDisabled) {
      setShowTempRegisterModal(true)
    }
  }

  // handle suggested messages
  const handleSuggestedMessages = (e: any, message: string) => {
    setUserResponse(message)
    handleSubmit(e, message)
  }

  return (
    <div className='chat-container d-flex flex-column justify-content-between mt-5 m-auto w-md-75 position-relative px-4'>
      <Chats messages={messages} isLoading={isLoading} />

      <div className='position-sticky bottom-0' style={{background: '#f9f9f9'}}>
        {showSuggestedBubble && (
          <>
            <div className='suggestion-bubble-container row m-0 mb-4 gap-2 d-lg-flex justify-content-around'>
              {suggestedMessages.map((msg) => (
                <button
                  className='suggestion-bubble-button col-md-6 p-0'
                  // disabled={isDisabled}
                  onClick={(e) => !isDisabled ? handleSuggestedMessages(e, msg.message): handleChatBarClick()}
                >
                  <p className='suggestion-bubble-text text-start mb-0 px-5 py-3'>{msg.message}</p>
                </button>
              ))}
            </div>
          </>
        )}

        <form
          onSubmit={(e) => handleSubmit(e)}
          className='chat-input-container d-flex px-3 position-sticky'
          onClick={handleChatBarClick}
        >
          <>
            {isDisabled && <div className='chat-bar-overlay' onClick={handleChatBarClick}></div>}
            <textarea
              ref={(element: any) => {
                // set both refs to the same element
                inputRef.current = element
                textAreaRef.current = element
              }}
              onClick={handleChatBarClick}
              disabled={isDisabled}
              onChange={(e) => handleInputChange(e)}
              value={userResponse}
              placeholder='Message Chloee...'
              className='chat-input'
              onKeyDown={(e: any) => handleKeyDown(e)}
            />
            <button
              disabled={isLoading}
              className={`${!isDisabled && 'chat-button-gradient'} chat-button`}
            >
              {isLoading ? (
                <span className='spinner-border spinner-border-sm' style={{color: '#fff'}}></span>
              ) : (
                <i className='fa fa-arrow-up' style={{color: '#fff'}} />
              )}
            </button>
          </>
        </form>
        <div className='justify-content-center text-center'>
          <p className='font-size-small'>
            Disclaimer: AI's can make mistakes, and cannot give investment advice. Please always
            double-check numbers, and recommendations with certified financial professionals.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Chatbot
