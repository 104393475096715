interface IFeedbackButtonProps {
    alt: string
    filledImage: string
    initialImage: string
    selected: boolean
    hovered: boolean
    onClick: () => void
    onMouseOver: () => void
    onMouseOut: () => void
    customClassName: string
  }
  
  const FeedbackButton: React.FC<IFeedbackButtonProps> = ({
    alt,
    filledImage,
    initialImage,
    selected,
    hovered,
    onClick,
    onMouseOver,
    onMouseOut,
    customClassName,
  }) => {
    const feedbackImage = selected || hovered ? filledImage : initialImage
  
    return (
      <img
        alt={alt}
        className={`thumbs-up-down-img ${customClassName}`}
        src={feedbackImage}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onClick={onClick}
      />
    )
  }
  
  export default FeedbackButton
  