import React, {useEffect, useState} from 'react'
import JoinWaitlistModal from '../../../../modals/JoinWaitlistModal'
import {useJoinWaitlistContext} from '../../../context/JoinWaitlistContext'
const JoinWaitlist = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const {showModal, setShowModal} = useJoinWaitlistContext()
  useEffect(() => {
    setShowModal(true)
  }, [])
  return (
    <div className='join-waitlist-container'>
      <JoinWaitlistModal show={showModal} onHide={() => setShowModal(false)} />
    </div>
  )
}

export default JoinWaitlist
