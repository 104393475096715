import React from 'react'
import html2canvas from 'html2canvas'
import {toast} from 'react-toastify'

const ShareAbleBubble: React.FC<{messageId: string}> = ({messageId}) => {
  const notifySuccess = (msg: string) => toast.success(msg)
  const generateImage = () => {
    const chartContainer = document.getElementById(messageId)
    if (chartContainer) {
      html2canvas(chartContainer)
        .then((canvas) => {
          canvas.toBlob((blob) => {
            if (blob) {
              try {
                navigator.clipboard
                  .write([new ClipboardItem({'image/png': blob})])
                  .then(() => {
                    notifySuccess('Chart image copied to clipboard')
                    console.log('Chart image copied to clipboard')
                  })
                  .catch((err) => {
                    console.error('Could not copy chart image to the clipboard', err)
                  })
              } catch (err) {
                console.error('An error occurred copying to clipboard', err)
              }
            }
          }, 'image/png') // Specify 'image/png' to get a PNG blob
        })
        .catch((err) => {
          console.error('An error occurred capturing the chart', err)
        })
    }
  }

  // The return statement should be part of the component function
  return (
    <div>
      {/* The generateImage function should be passed as a reference to the onClick handler */}
      <button className='chat-button chat-button-gradient ms-2 me-3' title='Share' onClick={generateImage}>
        <i className='fa fa-share' style={{color: '#fff'}} />
      </button>
    </div>
  )
}

export default ShareAbleBubble
