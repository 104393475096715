/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/waitlist'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Waitlist'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/users'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Users'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/daily-limit'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Daily Limit'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/analytics'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Analytics'
        fontIcon='bi-layers'
      />
    </>
  )
}

export {SidebarMenuMain}
