import {useEffect, useState} from 'react'
import MetaTags from '../../../Meta/MetaTags'
import CookiesBanner from '../../modules/banner/CookiesBanner'
import HeroSection from '../../modules/home/HeroSection'
import {Footer} from '../../modules/Footer/Footer'
import PersonalizationForm from '../../modules/personalization-form/PersonalizationForm'
import {useNavigate} from 'react-router-dom'
import JoinWaitlistModal from '../../../modals/JoinWaitlistModal'

const HomePage = () => {
  const [hideBanner, setHideBanner] = useState(false)
  const navigate = useNavigate()

  // fetching personalized-form from local storage
  const personalizationFormObj = localStorage.getItem('personalizationFormObj')

  useEffect(() => {
    // personalizationFormObj ? navigate('/chat-bot') : console.log('')
  }, [])
  return (
    <>
      {/* meta tags for SEO */}
      <MetaTags URL={window.location.href} />
      <PersonalizationForm />
    </>
  )
}

export {HomePage}
