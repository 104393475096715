import clsx from 'clsx'
import {FC} from 'react'
import {Nav} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import {ThemeModeSwitcher} from '../../../../partials'
import {NAVBAR_CONSTANTS, CONSTANTS} from '../../../../../app/constants/AppConstants'
import {useJoinWaitlistContext} from '../../../../../app/user/context/JoinWaitlistContext'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../../../app/modules/auth'

const itemClass = 'ms-1 ms-lg-3'

const NavbarComponent: FC = () => {
  const navigate = useNavigate()
  const {logout, currentUser} = useAuth()

  const {setShowModal} = useJoinWaitlistContext()
  return (
    <Navbar expand='lg' bg='light' data-bs-theme='primary' sticky='top'>
      <Container>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />

        <Navbar.Brand href='/'>
          <img alt='chloee-img' className='chloee-logo' src={CONSTANTS.IMAGES.LOGO} />
        </Navbar.Brand>

        <Navbar.Collapse id='basic-navbar-nav'>
          {/* <Nav className='me-auto'>
            <Nav.Link className='nav-link nav-text' href='#'>
              {NAVBAR_CONSTANTS.NAVLINK1}
            </Nav.Link>
            <Nav.Link className='nav-link nav-text' href='#'>
              {NAVBAR_CONSTANTS.NAVLINK2}
            </Nav.Link>
            <Nav.Link className='nav-link nav-text' href='#'>
              {NAVBAR_CONSTANTS.NAVLINK3}
            </Nav.Link>
          </Nav> */}

          <div className='ms-auto d-flex align-items-center py-2 py-lg-0'>
            {/* <div className={clsx('app-navbar-item', itemClass)}>
              <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
            </div> */}
            {/* <Nav>
              <Nav.Link className='nav-link nav-text' href='#'>
                {NAVBAR_CONSTANTS.NAVLINK4}
              </Nav.Link>
            </Nav> */}
            <a href='https://twitter.com/yourprofile' target='_blank' rel='noopener noreferrer'>
              <i
                className='fab fa-twitter social-icons-header'
                style={{color: '#1DA1F2'}}
                aria-hidden='true'
              />
            </a>
            <a href='https://instagram.com/yourprofile' target='_blank' rel='noopener noreferrer'>
              <i
                className='fab fa-instagram social-icons-header mx-5'
                style={{
                  background:
                    'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%)',
                  color: 'transparent',
                  WebkitBackgroundClip: 'text',
                  backgroundClip: 'text',
                }}
                aria-hidden='true'
              />
            </a>
            <a href='https://tiktok.com/@yourprofile' target='_blank' rel='noopener noreferrer'>
              <i
                className='fab fa-tiktok social-icons-header'
                style={{color: '#000000'}}
                aria-hidden='true'
              />
            </a>
          </div>
          {currentUser && (
            <>
              {/* <div className='secondary-btn ms-4' onClick={() => setShowModal(true)}>
                {NAVBAR_CONSTANTS.BUTTON}
              </div> */}
              <div className='secondary-btn ms-4 mt-2 mt-lg-0' onClick={() => navigate('/home')}>
                {NAVBAR_CONSTANTS.EDIT_PREFERENCES_BTN}
              </div>
              <div className='secondary-btn ms-4 mt-2 mt-lg-0' onClick={logout}>
                Logout
              </div>
            </>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export {NavbarComponent}
