import React, {createContext, useState, useContext, Dispatch, SetStateAction, useMemo} from 'react'
import {WithChildren} from '../../../_metronic/helpers'
import getDeviceInfo from '../../helpers/GenerateDeviceInfo'

// Join waitlist context props
type PastPerformanceContextProps = {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  deviceInfo: any
  setDeviceInfo: Dispatch<SetStateAction<any>>
  showTempRegisterModal: boolean
  setShowTempRegisterModal: Dispatch<SetStateAction<boolean>>
}

// default values
const initPastPerformanceContextPropsState = {
  showModal: false,
  setShowModal: () => {},
  deviceInfo: getDeviceInfo(),
  setDeviceInfo: () => {},
  showTempRegisterModal: false,
  setShowTempRegisterModal: () => {},
}

const JoinWaitlistContext = createContext<PastPerformanceContextProps>(
  initPastPerformanceContextPropsState
)

const JoinWaitlistProvider: React.FC<WithChildren> = React.memo(({children}) => {
  const [showModal, setShowModal] = useState(false)
  const [showTempRegisterModal, setShowTempRegisterModal] = useState(false)

  const [deviceInfo, setDeviceInfo] = useState<any>(getDeviceInfo())

  // This useMemo ensures that the context value only changes when any of the context properties change
  const contextValue = useMemo(
    () => ({
      showModal,
      setShowModal,
      deviceInfo,
      setDeviceInfo,
      showTempRegisterModal,
      setShowTempRegisterModal
    }),
    [showModal, deviceInfo, showTempRegisterModal]
  )
  return (
    <JoinWaitlistContext.Provider value={contextValue}>{children}</JoinWaitlistContext.Provider>
  )
})

const useJoinWaitlistContext = () => {
  const context = useContext(JoinWaitlistContext)
  if (!context) {
    throw new Error('useJoinWaitlistContext must be used within a JoinWaitListProvider')
  }
  return context
}

export {JoinWaitlistProvider, useJoinWaitlistContext}
